@import "./variable";

#about {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: $breakpoint-desktop-lg) {
      flex-direction: row;
    }

    .profile-img {
      width: 30%;

      @media (min-width: $breakpoint-desktop) {
        width: 35%;
      }

      @media (min-width: $breakpoint-desktop-lg) {
        width: 75%;
      }
    }

    .bio {
      @media (min-width: $breakpoint-desktop) {
        width: 100%;
      }

      .bio-text {
        font-size: 1rem;

        @media (min-width: $breakpoint-desktop) {
          font-size: 1.5rem;
        }
      }

      .skill-set {
        flex-wrap: wrap;
        max-width: 850px;
        // margin: 0 auto;

        .set {
          //   margin-bottom: 1rem;
          //   min-width: 280px;
          @media (min-width: $breakpoint-desktop) {
            margin-top: 3rem;
          }

          h3 {
            font-size: 1.25rem;

            @media (min-width: $breakpoint-desktop) {
              font-size: 2rem;
            }
          }

          .skills {
            display: grid;
            grid-template-columns: 0.3fr 0.3fr 0.3fr;
            //   grid-gap: 1rem;
            //   gap: 1rem;
            list-style: none;
            font-size: 1rem;

            @media (min-width: $breakpoint-desktop) {
              margin-top: 2rem;
              grid-template-columns: 1fr 1fr 1fr;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}
