// variables for styles

// font
$body-text-font: 'Open Sans', sans-serif;


//font size variables
$standard-font-size: 1rem;
$medium-font-size: 1.5rem;
$large-font-size: 2rem;

//colors variables
$dark-blue: #336699;
$light-blue: #86bbd8;
$dark-grey: #2b2b2b;
$light-grey: #e9ecef;
$white: #fff;
$orange: #ff9500;
$sp-color: #6ac967;


//media query breakpoints
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-desktop-lg: 1400px;