/*
=====================================================================
*   v1.0 Layout Stylesheet
*   03-12-2022
=====================================================================

   TOC:
   a. General Styles
   b. Header Styles
   c. About Section
   d. Resume Section
   e. Call To Action Section
   f. Testimonials Section
   g. Contact Section
   h. Footer

===================================================================== */

/* ------------------------------------------------------------------ */
/* a. General Styles
/* ------------------------------------------------------------------ */

// body { background: #0f0f0f; }

/* ------------------------------------------------------------------ */
/* b. Header Styles
/* ------------------------------------------------------------------ */

.navbar-nav > a {
  margin-left: 20px;
  margin-right: 20px;
}

/* ------------------------------------------------------------------ */
/* c. About Section
/* ------------------------------------------------------------------ */


 

 /* ------------------------------------------------------------------ */
/* d. Resume Section
/* ------------------------------------------------------------------ */
/* 


/*
.git {
  	-moz-animation: git 2s ease;
  	-webkit-animation: git 2s ease;
}
.illustrator {
  	-moz-animation: illustrator 2s ease;
  	-webkit-animation: illustrator 2s ease;
}
.reactjs {
  	-moz-animation: reactjs 2s ease;
  	-webkit-animation: reactjs 2s ease;
}
.css {
  	-moz-animation: css 2s ease;
  	-webkit-animation: css 2s ease;
}
.html5 {
  	-moz-animation: html5 2s ease;
  	-webkit-animation: html5 2s ease;
}
.vuejs {
  	-moz-animation: vuejs 2s ease;
  	-webkit-animation: vuejs 2s ease;
}
.mongodb {
  	-moz-animation: mongodb 2s ease;
  	-webkit-animation: mongodb 2s ease;
}

@-moz-keyframes git {
  0%   { width: 0px;  }
  100% { width: 60%;  }
}
@-moz-keyframes illustrator {
  0%   { width: 0px;  }
  100% { width: 55%;  }
}
@-moz-keyframes reactjs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-moz-keyframes css {
  0%   { width: 0px;  }
  100% { width: 90%;  }
}
@-moz-keyframes html5 {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}
@-moz-keyframes vuejs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-moz-keyframes mongodb {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}

@-webkit-keyframes photoshop {
  0%   { width: 0px;  }
  100% { width: 60%;  }
}
@-webkit-keyframes illustrator {
  0%   { width: 0px;  }
  100% { width: 55%;  }
}
@-webkit-keyframes reactjs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-webkit-keyframes css {
  0%   { width: 0px;  }
  100% { width: 90%;  }
}
@-webkit-keyframes html5 {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}
@-webkit-keyframes vuejs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-webkit-keyframes mongodb {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}  */



/* ------------------------------------------------------------------ */
/* g. Contact Section
/* ------------------------------------------------------------------ */
/* 
#contact {
   background: #191919;
   padding-top: 100px;
   padding-bottom: 102px;
   color: #fff;
} */

/* ------------------------------------------------------------------ */
/* h. Footer
/* ------------------------------------------------------------------ */

footer {
   background-color: #e9ecef;
}