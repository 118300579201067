@import "./_variable.scss";

/*
=====================================================================
*   v1.0 Default Stylesheet
*   03-12-2022
=====================================================================

TOC:
a. Webfonts and Icon fonts
b. Reset
c. Default Styles
   1. Basic
   2. Typography
   3. Colours
   4. Links
   5. List
   6. Images
   7. Buttons
   8. Forms
d. Media Queries
e. Grid
f. Others
   1. Misc

=====================================================================  */

/* ------------------------------------------------------------------ */
/* a. Webfonts and Icon fonts
 ------------------------------------------------------------------ */

/* // @import url("fonts.css");
// @import url("fontello/css/fontello.css");
// @import url("font-awesome/css/font-awesome.min.css"); */

audio,
canvas,
video {
  display: inline-block;
}

/* ------------------------------------------------------------------ */
/* b. Reset
 ------------------------------------------------------------------ */

/* ------------------------------------------------------------------ */
/* c. Default and Basic Styles
      Adapted from:
      Skeleton CSS Framework - http://www.getskeleton.com/
      Typeplate Typographic Starter Kit - http://typeplate.com/
/* ------------------------------------------------------------------ */

/* default
--------------------------------------------------------------- */

/* removed gutters
.row.collapsed > .column,
.row.collapsed > .columns,
.column.collapsed, .columns.collapsed  { padding: 0; }

[class*="column"] + [class*="column"]:last-child { float: right; }
[class*="column"] + [class*="column"].end { float: right; }

/*  1. Basic  ------------------------------------------------------- */

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; /* 62.5% of 16px base font size is 10px */
  -webkit-font-smoothing: antialiased;
}

body {
  background: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  color: #838c95;

  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
}

p,
li,
em,
a,
h1,
h4 {
  font-family: "Open Sans", sans-serif;
}

/*  2. Typography
       Vertical rhythm with leading derived from 6
--------------------------------------------------------------------- */

/*  3. Colours  ------------------------------------------------------- */

.text-dark-grey {
  color: #2b2b2b;
}

/* .sp-text-color {
    color: #a1dd9f;
 } */

.sp-text-color {
  color: #6ac967;
}

.sp-bg-color {
  background-color: #6ac967;
}

/* h4 {
  color: white;
} */

/*  4. Links  ------------------------------------------------------- */

/* a, a:visited {
   text-decoration: none;
   outline: 0;
   color: #11ABB0;

   -webkit-transition: color .3s ease-in-out;
   -moz-transition: color .3s ease-in-out;
   -o-transition: color .3s ease-in-out;
   transition: color .3s ease-in-out;
}
a:hover, a:focus { color: #313131; }
p a, p a:visited { line-height: inherit; } */

/*  5. List  --------------------------------------------------------- */

/* ul, ol { margin-bottom: 24px; margin-top: 12px; }
ul { list-style: none outside; }
ol { list-style: decimal; }
ol, ul.square, ul.circle, ul.disc { margin-left: 30px; }
ul.square { list-style: square outside; }
ul.circle { list-style: circle outside; }
ul.disc { list-style: disc outside; }
ul ul, ul ol,
ol ol, ol ul { margin: 6px 0 6px 30px; }
ul ul li, ul ol li,
ol ol li, ol ul li { margin-bottom: 6px; }
li { line-height: 18px; margin-bottom: 12px; }
ul.large li { }
li p { } */

/*  6. Images  --------------------------------------------------------- */

/*  7. Buttons  --------------------------------------------------------- */

.btn-light:hover {
  color: #fff !important;
  background-color: #313131 !important;
  border-color: whitesmoke !important;
}

/*  8. Forms  ------------------------------------------------------- */

/* ------------------------------------------------------------------ */
/* d. M E D I A  Q U E R I E S
 ------------------------------------------------------------------ */
/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints

/*  X-Small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

@media (min-width: $breakpoint-mobile) {
  // h3
}

@media (min-width: $breakpoint-tablet) {

}

@media (min-width: $breakpoint-desktop) {

}

@media (min-width: $breakpoint-desktop-lg) {

}

/* ------------------------------------------------------------------ */
/* e. Grid
 ------------------------------------------------------------------ */

/* ------------------------------------------------------------------ */
/* f. Others
 ------------------------------------------------------------------ */

/*  1. Misc -------------------------------------------------------- */
