@import "./variable";

#work {
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            color: $dark-grey;
            font-size: 2.5rem;
            font-weight: bold;
            text-align: center;
            margin-top: 1.5rem;

            @media (min-width: $breakpoint-desktop) {
                // background-color: $sp-color;
                // color: $white;
            }
        }

        .project-container{
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: $breakpoint-desktop) {
                flex-direction: row;
            }

            .project{
                padding: 0.5rem;

                @media (min-width: $breakpoint-desktop) {
                    padding: 3rem;
                }

                img {
                    width: 50%;
                    display: flex;
                    margin: 0 auto;

                    @media (min-width: $breakpoint-mobile) {
                        width: 35%;
                    }

                    @media (min-width: $breakpoint-desktop) {
                        width: 75%;
                        display: block;
                        margin: 0;
                    }
                }

                h3 {
                    font-size: 1.5rem;

                    @media (min-width: $breakpoint-desktop) {
                        font-size: 2rem;
                        margin-top: 2rem;
                    }
                }

                ul {
                    list-style: none;
                    flex-wrap: wrap;
                    display: flex;
                    padding-left: 0;
                    margin-bottom: 0;

                    @media (min-width: $breakpoint-desktop) {
                        margin-top: 1rem;
                    }

                    li {
                        margin-left: 0.25rem;
                        margin-right: 0.25rem;
                        padding-left: 0.25rem;
                        padding-right: 0.25rem;
                        border: none;
                        font-weight:bold;
                        font-size: 1rem;

                        @media (min-width: $breakpoint-desktop) {
                            // flex-wrap: wrap;
                            margin: 0.5rem;
                            font-size: 1.25rem;
                            border: $dark-grey solid 2px;
                            border-radius: 0.25rem;
                        }
    
                    }
                }

                .work-description {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    margin-top: 0;

                    @media (min-width: $breakpoint-desktop) {
                        margin-top: 1rem;
                        font-size: 1.5rem;
                        line-height: 3rem;
                    }

                }
            }
        }
    }
}