@import "./variable";

.landing {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: $breakpoint-desktop) {
        flex-direction: row;
        align-items: center;
    }

    .comp {
      align-self: center;
      margin: 2rem;

      

      .fa-laptop-code {
        color: $dark-grey;
      }

      .name {
        font-size: 3rem;

        @media (min-width: $breakpoint-desktop) {
            font-size: 4rem;
        }
      }
    }
  }
}
